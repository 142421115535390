/* tamil */
@font-face {
    font-family: 'Mukta Malar';
    font-style: normal;
    font-weight: 400;
    src: local('Mukta Malar Regular'), local('MuktaMalar-Regular'), url(MCoXzAXyz8LOE2FpJMxZqIvpJ_U5wA.woff2) format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mukta Malar';
    font-style: normal;
    font-weight: 400;
    src: local('Mukta Malar Regular'), local('MuktaMalar-Regular'), url(MCoXzAXyz8LOE2FpJMxZqIvpJ_U5wA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mukta Malar';
    font-style: normal;
    font-weight: 400;
    src: local('Mukta Malar Regular'), local('MuktaMalar-Regular'), url(MCoXzAXyz8LOE2FpJMxZqIv_J_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: "Panchali";
    src: url("/assets/fonts/TAU_Elango_Panchali.TTF");
  }
  
  @font-face {
    font-family: "DigitalFont";
    src: url("/assets/fonts/digital-7.ttf");
  }
  